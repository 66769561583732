/* Reset some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "dmSans";
  /*Can be any text*/
  src: local("DMSans-Regular"),
    url("./assets/fonts/DMSans-Regular.ttf") format("truetype");
}


/* Basic styling for the root div */
#root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  /* colors */
  --primary-color: #f7bd98;
}


.gm-style-iw {
  position: relative;
  border: 2px solid #f7bd98; 
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    53.9% 100%,
    55% calc(102% - 2px),
    45% calc(102% - 2px),
    46% 100%,
    0 100%
  );
  box-shadow: none;
}
.gm-style .gm-style-iw-c {
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(-50%, -100%, 0);
  transform: translate3d(-50%, -98.6%, 0);
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.gm-style-iw-tc {
  background-color: #f7bd98;
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  z-index: 1;
}

.gm-style .gm-style-iw-tc::after {
  background: #fff; 
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  content: "";
  height: 12px;
  left: 0;
  position: absolute;
  top: -2.7px;
  width: 25px;
  z-index: 1;
}

